import { Types } from './types'

const initialState = {
    dataUserAdmin: {
        paging: {},
        rows: [],
    },
    dataTask: {
        paging: {},
        rows: []
    },
    dataWalletHistory: {
        paging: {},
        rows: []
    }
}
const homeReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_USER_ADMIN:
            return {
                ...newState,
                dataUserAdmin: action.payload
            }
        case Types.SAVE_LIST_TASK:
            return {
                ...newState,
                dataTask: action.payload
            }
        case Types.SAVE_WALLET_HISTORY:
            return {
                ...newState,
                dataWalletHistory: action.payload
            }
        default:
            return { ...newState }
    }
}
export default homeReducer

import { Types } from './types'

const initialState = {
    dataGroupTask: {
        paging: {},
        rows: []
    },
}
const groupTaskReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_GROUP:
            return {
                ...newState,
                dataGroupTask: action.payload
            }
        default:
            return { ...newState }
    }
}
export default groupTaskReducer
